import Modal from '@trendmicro/react-modal';
import * as React from 'react';
import { AuthContext } from 'services';
import {
  Grid,
  Header,
  Segment,
  Button,
  Select
} from 'semantic-ui-react';
import { AppState, AppThunkDispatch } from 'store';
import { connect } from 'react-redux';
import { getPublishVersions, publishToVersion } from 'state/scope/ScopeManagement.actions';
import { isReady } from 'state/scope/Scope.types';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AxiosInstance } from 'axios';
import { toast } from 'react-toastify';

import {
  usePublishVersionsToDropdowns,
  useHandleKeyPress,
  useSetSelectedStringVersion,
  useGetPublishVersions,
  useHandleChangeStringVersion
} from 'utils/component/hooks/hooks';
import 'components/InitializePlan/_InitializePlan.scss';
import AnchorRadioSelect from 'components/AnchorRadioSelect/AnchorRadioSelect';
import { PlanId } from 'state/scope/codecs/PlanMetadata';
import { TopMembers } from 'services/Scope.client';
import { getScopeObject, planFromSpace } from 'components/Scopebar/ScopeUtils';
import { mapValues } from 'lodash';

const mapStateToProps = (state: AppState) => {
  const { scope , settings} = state;
  const labelDimenion = settings.dimensionLabelProperty;

  if (isReady(scope)) {
    const anchor = getScopeObject(scope.mainConfig.memberTrees);
    return {
      scope,
      publishVersions: state.scopeManagement.publishVersions,
      plans: scope.mainConfig.initializedPlans,
      anchor,
      labelDimenion
    };
  }

  return {
    scope,
    publishVersions: state.scopeManagement.publishVersions,
    plans: [],
    labelDimenion
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => {
  return {
    getPublishVersions: (currentSubmitPlanId: PlanId) => dispatch(getPublishVersions(currentSubmitPlanId)),
    publishToVersion: (version: string, currentSubmitPlanId: PlanId) => {
      return dispatch(publishToVersion({ versionToPublishTo: version, applyTo: currentSubmitPlanId }));
    }
  };
};


export type SubmitPlanProps = SubmitPlanValueProps & SubmitPlanDispatchProps & SubmitPlanOwnProps;
export type SubmitPlanValueProps = ReturnType<typeof mapStateToProps>;
export type SubmitPlanDispatchProps = ReturnType<typeof mapDispatchToProps>;
export type DispatchGetPublishVersions = SubmitPlanDispatchProps['getPublishVersions'];
export type SubmitPlanOwnProps = {
  loading: boolean,
  onSubmit: (id?: number) => void,
  onCancel: () => void,
  onOpen: () => void
}

const SubmitPlanModal = (props: SubmitPlanProps) => {
  const {
    onCancel,
    onSubmit,
    scope,
    getPublishVersions: dispatchedGetPublishedVersions,
    publishVersions,
    publishToVersion,
    plans,
    labelDimenion,
    anchor
  } = props;
  // TODO: implement default version selecting

  const logger = useContext(AuthContext)!.serviceEnv.logging;
  const loading = !publishVersions;
  const [selectedVersion, setSelectedVersion] = useState<undefined | string>(undefined);

  const [currentSubmitPlanId, setCurrentSubmitPlanId] = useState<PlanId>(plans[0].id);
  const handleChangeSubmitSelections = useCallback((newMembers: TopMembers) => {
    if (!isReady(scope)) { return; }
    setCurrentSubmitPlanId(planFromSpace(scope.mainConfig.initializedPlans, newMembers).id);
  }, [scope, setCurrentSubmitPlanId]);

  const handleSubmit = useCallback(() => {
    if (selectedVersion && typeof selectedVersion === 'string') {
      publishToVersion(selectedVersion, currentSubmitPlanId)
        .catch((err) => {
          toast.error('An error occured submitting your version', {
            position: toast.POSITION.TOP_LEFT
          });
          logger.error(`An error occured submitting the version: ${selectedVersion}`, err);
        }).finally(() => {
          // this closes the modal
          onSubmit();
        });
    }
  }, [selectedVersion, publishToVersion, currentSubmitPlanId, logger, onSubmit]);

  const handleEnterPress = useHandleKeyPress(handleSubmit);
  const versionDropdowns = usePublishVersionsToDropdowns(publishVersions);
  useSetSelectedStringVersion(versionDropdowns, setSelectedVersion, selectedVersion);
  useGetPublishVersions(dispatchedGetPublishedVersions, currentSubmitPlanId);
  const handleChangeVersion = useHandleChangeStringVersion(setSelectedVersion);


  const getValueFromScope = isReady(scope) ? scope.currentAnchors : undefined;

  return (
    <React.Fragment>
      <div className="initialize-plan">
        <Grid columns={1} doubling={true} stretched={true}>
          <Grid.Column>
            <Segment>
              <Header as="h3" className="initialize-plan-header">
                {/* TODO: This text needs to be conditional.  If only one submit target exists,
                lock the dropdown and  change the "targeted version" text just say the only version name*/}
                Submit your WP to the selected Version
              </Header>
              <div>
                {getValueFromScope ?
                  <AnchorRadioSelect
                    labelDimenion={labelDimenion!}
                    anchor={anchor!}
                    onUpdateAnchorSelections={handleChangeSubmitSelections}
                  /> :
                  null
                }
              </div>
              <div className="dropdown-group">
                <Select
                  fluid={true}
                  loading={loading}
                  disabled={!isReady(scope)}
                  data-qa="submit-plan-dropdown"
                  icon={<i className="chevron far fa-chevron-down icon" />}
                  options={versionDropdowns}
                  value={selectedVersion}
                  onChange={handleChangeVersion}
                />
              </div>
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
      <Modal.Footer>
        <Button content="Cancel" onClick={onCancel} />
        <Button
          content="Submit"
          className="import-version-modal-button"
          data-qa="submit-btn-submit"
          onClick={handleSubmit}
          loading={loading}
          onKeyPress={handleEnterPress}
        />
      </Modal.Footer>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmitPlanModal);


