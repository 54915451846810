import _, { defaultTo } from 'lodash';
import { Field } from './Field';
import { Group } from './Group';
import Intersection from './Intersection';

export interface PivotParams {
  scopeId: string | undefined,
  rows: Group[],
  columns: Group[],
  readOnly?: boolean
}

function product(fields: Field[][]): Field[][] {
  return _.reduce(
    fields,
    // @ts-ignore
    (a, b) => _.flatten(_.map(a, x => _.map(b, y => x.concat([y])))),
    [[]]
  );
}

export default class PivotConfig {
  public constructor(input: PivotParams) {
    this.rows = input.rows;
    this.columns = input.columns;
    this.readOnly = defaultTo(input.readOnly, false);
    this.scopeId = input.scopeId!;
    this.reset();
  }

  public readonly scopeId: string;
  public readonly readOnly: boolean;
  protected rows: Group[];
  protected columns: Group[];
  // should these be initialized to []?
  protected rowIntersections!: Intersection[];
  protected colIntersections!: Intersection[];
  protected visibleRowFields!: Field[][];
  protected visibleColFields!: Field[][];
  /**
   * Pivot must contain some FieldGroup
   * @param {PivotConfig} p
   * @returns {boolean}
   */
  private static isNotEmpty(p: PivotConfig): boolean {
    return p.columns.length !== 0 && p.rows.length !== 0;
  }

  /**
   * Pivot must contain at least one measure to work properly
   * @param {PivotConfig} p
   * @returns {boolean}
   */
  private static containsMeasures(p: PivotConfig): boolean {
    let measureIndex = p.columns.findIndex(v => v.isMeasureGroup());

    if (measureIndex >= 0) {
      return p.columns[measureIndex].getVisible().length !== 0;
    }

    measureIndex = p.rows.findIndex(v => v.isMeasureGroup());
    if (measureIndex >= 0) {
      return p.rows[measureIndex].getVisible().length !== 0;
    }
    return false;
  }

  public reset() {
    this.visibleRowFields = this.rows
      .map(fg => fg.getVisible())
      .filter(fg => fg.length);
    this.visibleColFields = this.columns
      .map(fg => fg.getVisible())
      .filter(fg => fg.length);
    this.rowIntersections = product(this.visibleRowFields).map(
      cp => new Intersection(cp)
    );
    this.colIntersections = product(this.visibleColFields).map(
      cp => new Intersection(cp)
    );
  }

  public isValid(): boolean {
    return PivotConfig.isNotEmpty(this) && PivotConfig.containsMeasures(this);
  }

  public getRowIntersections(): Intersection[] {
    return this.rowIntersections;
  }

  public getColIntersections(): Intersection[] {
    return this.colIntersections;
  }

  public getRowGroups() {
    return this.rows;
  }

  public getColGroups() {
    return this.columns;
  }

  public getRowGroupsCount() {
    return this.rows.length;
  }

  public getColGroupsCount() {
    return this.columns.length;
  }

  public getVisibleRowGroupCount() {
    return this.visibleRowFields.length;
  }

  public getVisibleColGroupCount() {
    return this.visibleColFields.length;
  }

  public equals(that: PivotConfig): boolean {
    const thisRows = this.getRowGroups();
    const thatRows = that.getRowGroups();
    const thisCols = this.getColGroups();
    const thatCols = that.getColGroups();

    if (this.scopeId !== that.scopeId) {
      return false;
    }

    if (thisRows.length !== thatRows.length) {
      return false;
    }
    if (thisCols.length !== thatCols.length) {
      return false;
    }

    for (let i = 0; i < thisRows.length; ++i) {
      const result = thisRows[i].equals(thatRows[i]);
      if (!result) {
        return false;
      }
    }

    for (let i = 0; i < thisCols.length; ++i) {
      const result = thisCols[i].equals(thatCols[i]);
      if (!result) {
        return false;
      }
    }

    return true;
  }

  public findDimension(dimensionId: string): Group | undefined {
    return [this.rows, this.columns].flatMap(g => g).find((group) => {
      return group.dimension.id === dimensionId;
    });
  }

  public clone(): PivotConfig {
    return new PivotConfig({
      scopeId: this.scopeId,
      rows: this.rows.map(r => r.clone()),
      columns: this.columns.map(c => c.clone()),
      readOnly: this.readOnly
    });
  }
}
