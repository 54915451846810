import {
  PRODUCT,
  TIME,
  LOCATION,
  PRODLIFE,
  VARIANCE_VERSION,
  SINGLE_VERSION,
  COLUMNS,
  PERCENT_TO_TOTAL_VERSION,
  PERCENT_TO_GRAND_TOTAL_VERSION
} from 'utils/domain/constants';
import * as iots from 'io-ts';
import { ROWS } from '../utils/domain/constants';

export const DIMENSION_TYPE = 'Dimension';
export const METRIC_TYPE = 'Metric';
export const VERSION_TYPE = 'Version';

export const DELTA_VARTYPE = 'delta';
export const PERCENTAGE_VARTYPE = 'percentage';

export const DimensionPivotMapIO = iots.type({
  type: iots.literal(DIMENSION_TYPE),
  on: iots.union([iots.literal(PRODUCT), iots.literal(TIME), iots.literal(LOCATION), iots.literal(PRODLIFE)]),
  members: iots.array(iots.string, 'dimensionMembers')
});
export type DimensionPivotMap = iots.TypeOf<typeof DimensionPivotMapIO>

export const MetricPivotMapIO = iots.type({
  type: iots.literal(METRIC_TYPE),
  members: iots.array(iots.string, 'metricMembers')
});
export type MetricPivotMap = iots.TypeOf<typeof MetricPivotMapIO>;

export const SingleVersionType = iots.type({
  // unions of string literals use 'keyof' instead of iots.union
  type: iots.keyof({
    [SINGLE_VERSION]: null,
    [PERCENT_TO_TOTAL_VERSION]: null,
    [PERCENT_TO_GRAND_TOTAL_VERSION]: null
  }),
  hidden: iots.boolean,
  version: iots.string
});

export const VarianceType = iots.type({
  type: iots.literal(VARIANCE_VERSION),
  hidden: iots.boolean,
  version: iots.string,
  against: iots.string,
  varType: iots.keyof({
    [DELTA_VARTYPE]: null,
    [PERCENTAGE_VARTYPE]: null
  })
});

const VersionPivotMapMembersIO = iots.array(iots.union(
  [
    SingleVersionType,
    VarianceType
  ]), 'versionMembers');
export const VersionPivotMapIO = iots.type({
  type: iots.literal(VERSION_TYPE),
  members: VersionPivotMapMembersIO
});

export type VersionMembers = iots.TypeOf<typeof VersionPivotMapMembersIO>
export type VersionPivotMap = iots.TypeOf<typeof VersionPivotMapIO>;

export const PivotMapIO = iots.taggedUnion('type', [VersionPivotMapIO, MetricPivotMapIO, DimensionPivotMapIO]);
export type PivotMap = iots.TypeOf<typeof PivotMapIO>;

const PivotPayloadRequired = iots.type({
  rows: iots.array(PivotMapIO, 'rows'),
  columns: iots.array(PivotMapIO, 'cols')
});

const PivotPayloadOptional = iots.partial({
  pivotName: iots.string
});
export const PivotPayloadIO = iots.intersection([PivotPayloadRequired, PivotPayloadOptional]);
export type PivotPayload = iots.TypeOf<typeof PivotPayloadIO>;

export type PivotResponsePayload = {
  pivotName: string,
  [ROWS]: any,
  [COLUMNS]: any,
  rowAxes: string[], // dimesion ids
  colAxes: string[] // dimension ids
}


// export interface MetricPivotMap {
//   type: 'Metric',
//   members: string[]
// }

// export interface VersionPivotMap {
//   type: 'Version',
//   members: (VersionGroup | VarianceVersionGroup)[]
// }

// export interface VarianceVersionGroup {
//   type: 'VarianceVersion',
//   version: string,
//   against: string,
//   varType: 'delta' | 'percentage'
// }

// export interface VersionGroup {
//   type: 'SingleVersion',
//   version: string
// }

export interface LockState {
  cellLocked: boolean,
  globalLocked: boolean
}

export interface MockCell {
  row: Position['r'],
  col: Position['c'],
  value?: string,
  isLocked: boolean,
  isDisabled: boolean,
  isAlwaysDisabled: boolean,
  isConstrained: boolean,
  lockState: string,
  isLockable: boolean
}

export interface CellPosition {
  position: Position,
  cell: MockCell
}

export interface Position {
  r: number,
  c: number
}
