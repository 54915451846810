import * as React from 'react';
import { Popup } from 'semantic-ui-react';
import { openScopeSelectQaSelector } from '../Scopebar.types';
import { Button } from 'semantic-ui-react';
import {
  CONTEXT_PENDING,
  CONTEXT_READY,
  CONTEXT_FAILED,
  CONTEXT_STATUSES,
  CONTEXT_BUSY
} from 'state/workingSets/workingSets.types';
import { WorkingSetContext } from 'state/workingSets/workingSets.slice';
import './_ScopeStatusPopover.scss';
import { DEFAULT_DIMENSIONS, PerspectivePaths } from 'utils/domain/constants';
import ScopeText from '../ScopeText';
import { SCOPECREATE_WITHOUT_WP } from 'services/Scope.client';
import { Space } from 'space';

type ScopeStatusPopoverProps = {
  labelDimension: Space<string>,
  scopeId: string | undefined,
  onScopeClick: () => void,
  scopeContexts: WorkingSetContext[],
  handleUpdateScopeInfo: (scopeId: string, destinationPerspective: PerspectivePaths) => void
}

const ScopeStatusPopover = (props: ScopeStatusPopoverProps) => {
  const { scopeId, scopeContexts, onScopeClick, handleUpdateScopeInfo, labelDimension } = props;
  // we have to spread this as a copy, because the source array is ReadOnly and can't be modified
  const sortedContexts = [...scopeContexts].sort(sortByScopeCreationTime);
  return (
    <li className="scope-options-item scope-status-popover">
      <Popup
        trigger={
          <button
            className="scope-options-button"
            data-qa={openScopeSelectQaSelector}
            onClick={onScopeClick}
            type="button"
          >
            <i className="far fa-fw fa-crosshairs" />
            Scope
          </button>
        }
        wide="very"
        position="bottom right"
        className="scope-options-popup"
        on="hover"
        hoverable
      >
        <div className="scope-popover-container">
          <h4>Your current scopes: </h4>
          <Button.Group basic={true} vertical={true} className="scope-button-group">
            {sortedContexts.map((context) => {
              const scopeText = DEFAULT_DIMENSIONS.map((dim) => {
                //convert object into array
                let array = Object.values(context.anchor[dim]);
                return array.map(x => ` ${x[labelDimension[dim]]}`);
              }).join(' | ');
              if (context.initParams.type === SCOPECREATE_WITHOUT_WP) {
                return <div/>;
              }
              return (
                <Button
                  className={`scope-status-button ${context.id === scopeId ? 'current' : ''}`}
                  key={context.id}
                  onClick={(_evt) => handleUpdateScopeInfo(context.id, context.perspective)}
                >
                  {contextStateToIcon(context.status)}
                  <ScopeText content={scopeText} />
                </Button>
              );
            }
            )}
          </Button.Group>
        </div>
      </Popup>
    </li>
  );
};
export default ScopeStatusPopover;

const statusToIconMap = {
  [CONTEXT_READY]: 'far fa-check-circle',
  [CONTEXT_PENDING]: 'fas fa-spinner fa-spin',
  [CONTEXT_BUSY]: 'fas fa-spinner fa-spin',
  [CONTEXT_FAILED]: 'far fa-exclamation-triangle'
};

const contextStateToIcon = (contextState: CONTEXT_STATUSES): JSX.Element => {
  return (<i className={`scope-status-icon ${statusToIconMap[contextState]}`} />);
};

const sortByScopeCreationTime = (lScope: WorkingSetContext, rScope: WorkingSetContext) => {
  return lScope.contextCreationTime.unix() - rScope.contextCreationTime.unix();
};
