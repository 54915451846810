import * as React from 'react';
import { connect } from 'react-redux';
import { ButtonProps, Dropdown, DropdownItemProps, DropdownProps, Segment } from 'semantic-ui-react';
import { useMemo, useRef, useState } from 'react';
import { AppState, AppThunkDispatch } from 'store';
import { isReady } from 'state/scope/Scope.types';
import { ReseedPlanModalOwnProps } from 'components/Reseed/ReseedPlanModal';
import { useCallback, useEffect } from 'react';
import Modal from '@trendmicro/react-modal';
import { Button } from 'semantic-ui-react';
import { workflowOrPlanToMap } from 'state/scope/codecs/projections/workflowOrPlanToMap';
import { planToDropdown } from 'state/scope/codecs/projections/PlanMetadataToDropdown';
import { isEmpty, noop, head, mapValues } from 'lodash';
import { useHandleKeyPress } from 'utils/component/hooks/hooks';
import AnchorRadioSelect from 'components/AnchorRadioSelect/AnchorRadioSelect';
import { PlanId } from 'state/scope/codecs/PlanMetadata';
import { TopMembers } from 'services/Scope.client';
import { getScopeObject, planFromSpace } from 'components/Scopebar/ScopeUtils';
import { importVersion } from 'state/scope/scope.actions';

export interface ImportFromVersionOwnProps {
  loading: boolean
}

const mapStateToProps = (state: AppState) => {
  const { scope, settings } = state;
  const dimensionLabel = settings.dimensionLabelProperty;
  if (!isReady(scope)) {
    return {
      importOptions: undefined
    };
  }
  const anchor = getScopeObject(scope.mainConfig.memberTrees);

  return {
    initializedPlans: scope.mainConfig.initializedPlans,
    importOptions: scope.importOptions,
    scope,
    dimensionLabel,
    anchor
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => {
  return {
    handleImportVersion: (seedId: number, applyTo: PlanId) => {
      return dispatch(importVersion(seedId, applyTo));
    }
  };
};

export type ImportFromVersionProps =
  ReseedPlanModalOwnProps &
  ImportFromVersionOwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const ImportFromVersion = (props: ImportFromVersionProps) => {
  // TODO: may just make this an uncontrolled select, it might work better
  // TODO: pressing escape closes the dd

  const { importOptions, onCancel, onSubmit, scope, initializedPlans, handleImportVersion, dimensionLabel, anchor } = props;
  const [selectedVersion, setSelectedVersion] = useState<undefined | number>(undefined);
  const [ddOpen, setDdOpen] = useState(false);
  const loading = isEmpty(importOptions);
  const modalRef = useRef<HTMLDivElement | null>(document.querySelector('.titled-modal-entered'));
  const prevOptions = useRef<typeof importOptions>();
  const close = useCallback(() => ddOpen ? setDdOpen(false) : noop(), [ddOpen]);

  const [
    currentImportPlanId,
    setCurrentImportPlanId
  ] = useState<PlanId | null>(head(initializedPlans) ? head(initializedPlans)!.id : null);
  const handleChangeImportSelections = useCallback((newMembers: TopMembers) => {
    if (!initializedPlans) { return; }
    setCurrentImportPlanId(planFromSpace(initializedPlans, newMembers).id);
  }, [initializedPlans, setCurrentImportPlanId]);

  useEffect(() => {
    // all of this garbage exists because the semantic dd doesn't bind the blur event on the first open
    // so we've got to manually jiggle it from the titled modal above
    // because there isn't another good way to get this click
    // TODO: fix this or replace the DD

    if (modalRef.current) {
      modalRef.current.addEventListener('click', close);
    } else {
      modalRef.current = document.querySelector('.titled-modal-entered');
    }
    return () => modalRef.current ? modalRef.current.removeEventListener('click', close) : noop();
  }, [close, ddOpen, importOptions]);
  const options: DropdownItemProps[] | undefined = useMemo(() => !isEmpty(importOptions) && currentImportPlanId ?
    importOptions![currentImportPlanId].map((pln) => workflowOrPlanToMap(pln, planToDropdown)) :
    undefined,
    [currentImportPlanId, importOptions]);

  // if coming from undef options to new options, open the dd
  // this is why the dd needs to be controlled
  useEffect(() => {
    if (!prevOptions.current && options && !isEmpty(options) && !ddOpen) {
      setDdOpen(true);
    }
    prevOptions.current = importOptions;
  }, [importOptions, options, ddOpen]);

  const handleSubmit = useCallback(() => {
    if (selectedVersion && typeof selectedVersion === 'number' && currentImportPlanId) {
      handleImportVersion(selectedVersion, currentImportPlanId).finally(() => {
        onSubmit();
      });
    }
  }, [currentImportPlanId, handleImportVersion, onSubmit, selectedVersion]);
  const onChange = useCallback((_event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    if (data.value && typeof data.value === 'number') {
      setSelectedVersion(data.value);
      // have to manually close here because the dd doesn't always close onChange
      setDdOpen(false);
    }
  }, [setSelectedVersion]);
  const handleOnSubmit = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: ButtonProps) => {
    handleSubmit();
  }, [handleSubmit]);
  const handleEnterPress = useHandleKeyPress(handleSubmit);

  return (
    <div className="import-version">
      <Modal.Body>
        <Segment>
          Select a version to import into your WP
        </Segment>
        <div>
          {scope && dimensionLabel && anchor ?
            <AnchorRadioSelect
              labelDimenion={dimensionLabel}
              anchor={anchor}
              onUpdateAnchorSelections={handleChangeImportSelections}
            /> :
            null}
        </div>
        <Dropdown
          data-qa="import-version-dropdown"
          loading={loading}
          fluid={true}
          search={true}
          selection={true}
          options={options}
          onChange={onChange}
          onBlur={close}
          open={ddOpen}
          onClick={() => setDdOpen(true)}
          onKeyPress={handleEnterPress}
          value={selectedVersion}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button content="Cancel" onClick={onCancel} />
        <Button
          content="Submit"
          className="import-version-modal-button"
          data-qa="version-import-btn-import"
          loading={loading}
          onClick={handleOnSubmit}
          onKeyPress={handleEnterPress}
        />
      </Modal.Footer>
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(ImportFromVersion);

