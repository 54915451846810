import { combineEpics, Epic } from 'redux-observable';
import {
  setMetricConfigEpic,
  requestSummarizedDataEpic
} from 'components/MacroSummaries/MacroSummaries.listener';
import { setupSettings, setLocalization } from 'components/MainContainer/MainContainer.listener';
import { AppState, EpicDependencies } from 'store';
import {
  setupViewConfig,
  setupAuthViewConfigEpic,
  setupActiveViews,
  completeUnfinishedRoute
} from 'state/ViewConfig/ViewConfig.listener';
import { refreshGrid, setEopActuals, addPrivateVersion } from 'state/scope/Scope.listener';
import {
  refreshNonWorkingSummary,
  refreshWorkflowsOnLatestPublish,
  setActiveSummaryPlan
} from 'state/workingSets/nonWorkingSets.listener';
import { scopeReadyMapActualOptions } from 'state/scope/ScopeManagement.listener';
import { changeEditableStateOfComments, scopeReadyGetComments } from 'components/Comments/Comments.listener';
import { saveLastCreatedScope } from 'state/uipose/UiPose.listener';

// TODO: fix input/output action types here
export type AppEpic = Epic<any, any, AppState, EpicDependencies>

export const rootEpic = combineEpics(
  setMetricConfigEpic,
  requestSummarizedDataEpic,
  setupViewConfig,
  setupSettings,
  setLocalization,
  completeUnfinishedRoute,
  setupAuthViewConfigEpic,
  setupActiveViews,
  refreshGrid,
  setActiveSummaryPlan,
  refreshNonWorkingSummary,
  setEopActuals,
  setEopActuals,
  addPrivateVersion,
  scopeReadyMapActualOptions,
  refreshWorkflowsOnLatestPublish,
  scopeReadyGetComments,
  changeEditableStateOfComments,
  saveLastCreatedScope
);
