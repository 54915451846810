import { Dimension } from './Dimension';
import { Field } from './Field';
import { HierarchyMember } from './Member';
import { METRICS, REVISIONS } from 'utils/domain/constants';

export class Group {
  public readonly dimension: Dimension;
  protected rootMember: HierarchyMember;
  protected nested: boolean;
  constructor(rootMember: HierarchyMember, nested: boolean) {
    this.rootMember = rootMember;
    this.dimension = rootMember.level.dimension;
    this.nested = nested;
  }

  public isMeasureGroup(): boolean {
    return this.rootMember.level.dimension.id === METRICS;
  }

  public isRevisionGroup(): boolean {
    return this.rootMember.level.dimension.id === REVISIONS;
  }

  public getVisible(): Field[] {
    const self = this;
    function getLevelFields(member: HierarchyMember, results: Field[], depth: number) {
      let newDepth = depth;
      if (!member.isHidden) {
        const gf: Field = {
          member,
          depth,
          group: self
        };

        results.push(gf);
        newDepth++;
      }
      const newResults = results;
      if (!member.isChildrenHidden) {
        const children = member.children || [];
        for (const child of children) {
          newResults.push.apply(getLevelFields(child, newResults, newDepth));
        }
      }
      return newResults;
    }
    return getLevelFields(self.rootMember, [], 1);
  }

  public isNested(): boolean {
    return this.nested;
  }

  public equals(that: Group) {
    if (this.nested !== that.nested) {
      return false;
    }
    const thisVisible = this.getVisible();
    const thatVisible = that.getVisible();
    if (thisVisible.length !== thatVisible.length) {
      return false;
    }

    for (let i = 0; i < thisVisible.length; ++i) {
      const result = thisVisible[i].member.id === thatVisible[i].member.id;
      if (!result) {
        return false;
      }
    }
    return true;
  }

  public clone(): Group {
    function copy(
      m: HierarchyMember,
      parent?: HierarchyMember
    ): HierarchyMember {
      const {
        id,
        name,
        description,
        type,
        level,
        isHidden,
        isChildrenHidden,
        format,
        areChildrenColumnsVisible,
        varType
      } = m;
      const result: HierarchyMember = {
        id,
        name,
        description,
        type,
        level,
        parent,
        isHidden,
        isChildrenHidden,
        format,
        areChildrenColumnsVisible,
        varType
      };
      if (m.children) {
        result.children = m.children.map(child => copy(child, result));
      }
      return result;
    }
    return new Group(copy(this.rootMember), this.nested);
  }
}
